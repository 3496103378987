*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}
p {
    margin-bottom: 0 !important;
}
a{
  text-decoration: none !important;
}

html{
  font-family: 'EnnVisions' !important;
}

body{
  font-family: "EnnVisions" !important;
  background-color: #FBF9F9 !important;
  box-sizing: border-box;
}

@font-face {
  font-family: "EnnVisions";
  src: local("SFUIText-Regular"),
      url("./Assets/fonts/EnnVisions.otf") format("truetype");
}
@font-face {
  font-family: "EnnVisionsBold";
  src: local("SFUIText-Regular"),
      url("./Assets/fonts/EnnVisionsMedium.otf") format("truetype");
}


.userapp-main-container{
  max-width: 380px;
  margin-left: auto;
  margin-right: auto;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected{
  background-color: transparent !important;
}
.ant-layout-header{
  background: white !important;
}
.ant-table-thead .ant-table-cell{
  padding: 9px  !important;
  color: black;
  font-weight: 600;
}
.ant-table-tbody .ant-table-cell{
  padding: 7px  !important;
}
.userTableLink{
  color: black;
}
.userTableLink:hover{
  color: black;
}
.ant-modal-content{
  border-radius: 7px !important;
}
.ant-table{
  height: 75vh;
}
.ant-menu{
 height: calc(100vh - 68px) !important;
}
.anticon{
  display: block !important;
}
.ant-modal-close{
  top: 15px !important;
}
/* .detail-buttons{
  margin-top: 30px !important;
  height: auto !important;

} */